import React from 'react';
import styles from './style.module.scss';
import Button from '../../Button';
import getParams from '../../../utils/getParams';
import { EXT_PATH } from '../../../utils/constant';
import { useLocation } from 'react-router';

export default ({ variant = 'fill' }) => {
  const location = useLocation();
  const subId = getParams(location.search).subid;
  const installExtHandler = () => {
    window.open(`${EXT_PATH}${subId ? `?subid=${subId}` : '?subid=sitebutton'}`,'_blank');
  }
  switch (variant) {
    case 'outlined':
      return (<Button
        text="Install Extension"
        onClick={installExtHandler}
        customButtonClass="donateButton"
      />);
    default:
      return (
        <button
          className={styles.extensionBtn}
          onClick={installExtHandler}
        >Add to chrome</button>
      );
  }
};
