import { SET_HEARTS } from '../types/donateTypes';

const initialState = {
  hearts: null,
};
export default function donate(state = initialState, action) {
  switch (action.type) {
    case SET_HEARTS:
      return {
        hearts: action.hearts,
      };
    default:
      return state;
  }
}
