import { OPEN_POPUP } from '../types/popupTypes';
import thankImage from '../../media/thank.png';

const initialState = {
  status: false,
  text: '',
  image: thankImage,
};
export default function popUp(state = initialState, action) {
  switch (action.type) {
    case OPEN_POPUP:
      return {
        status: action.status,
        text: action.text,
        image: action.image,
      };
    default:
      return state;
  }
}
