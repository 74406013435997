import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class Index extends Component {
  componentDidUpdate(prevProps) {
    const { location: pathname } = this.props;

    document.querySelector('body').scrollTo(0, 0);
  }
  componentDidMount() {
    document.querySelector('body').scrollTo(0, 0);

  }

  render() {
    // eslint-disable-next-line no-shadow
    const { component: Component, ...rest } = this.props;

    return <Route {...rest} render={(props) => (<Component {...props} />)} />;
  }
}
Index.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any,

};

Index.defaultProps = {
  location: '',
  component: '',
};
export default withRouter(Index);
