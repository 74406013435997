import React, { useState } from 'react';
import styles from './style.module.scss';
import { ReactComponent as SearchIcon } from '../../../media/searchIcon.svg';
import { ReactComponent as ClearIcon } from '../../../media/clearIcon.svg';
import BanerImg from '../../../media/landingBanner.svg';
import logo from '../../../media/logo.svg';
import InstallButton from '../InstallButton';

const getSearchURL = (q) => new URL(`https://search.qooly.com/search/?id=extid&se=bing&source=omnibox&q=${q}`);
const normaliseQuery = (q) => q.trim().replace(/\s{2,}/g, ' ');


export default () => {
  const [val, setValue] = useState('');
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const query = normaliseQuery(val);
    if (query.length < 2) {
      return ;
    }
    const queryURL = getSearchURL(query);
    window.open(queryURL.href);
  };
  const onInputHandler = (e) => {
    const { value } = e.target;
    setValue(value);
  };
  const onBlureHandler = () => {
    setValue(normaliseQuery(val));
  };
  const onClearHandler = (e) => {
    e.preventDefault();
    setValue('');
  };
  return (
    <section className={styles.banner}>
      <div className={styles.content}>
        <img className={styles.bannerImage} src={logo} alt=""/>
        <h1 className={styles.title}>
          We can help charities with our search
        </h1>
        <form
          action="#"
          className={styles.searchInput}
          onSubmit={onSubmitHandler}
          autoComplete="off"
        >
          <input
            name="query"
            value={val}
            type="search"
            onChange={onInputHandler}
            onBlur={onBlureHandler}
            placeholder="Search in web and help people..."
          />
          {
            !!val &&
            <button
              type="button"
              onClick={onClearHandler}
            >
              <ClearIcon />
            </button>
          }
          <button
            type="submit"
          >
            <SearchIcon />
          </button>
        </form>
        <p className={styles.description}>
              <span>
                Make EverySearchMatters your new default search engine and help charities
              </span> with your search - you can choose which organization to send the donation to!
        </p>
        <InstallButton/>
      </div>
      <div className={styles.image}>
        <img src={BanerImg} alt="We can help charities with our search"/>
      </div>
    </section>
  );
};
