import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import popupHeart from '../../media/popupHeart.svg';
import getParams from '../../utils/getParams';
import { EXT_PATH } from '../../utils/constant';

const UploadExtPopup = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const popUpRef = useRef('');
  const subId = getParams(location.search).subid;
  if (subId) {
    window.history.pushState(subId, 'Every Search matters', '/');
  }

  const closePoupHandler = () => {
    setOpen(false);
    document.body.style = 'overflow: auto;';
  };
  const closeByPoupBack = (e) => {
    if (e.target === popUpRef.current) {
      closePoupHandler();
    }
  };
  const onKeyDownHandler = (event) => {
    if (event.key === 'Escape') {
      closePoupHandler();
    }
  };
  if (open) {
    document.addEventListener('keydown', onKeyDownHandler);
  } else {
    document.removeEventListener('keydown', onKeyDownHandler);
  }
  return (
    <div
      className={`${styles.UploadExtPopup} UploadExtPopup`}
      style={!open ? { display: 'none' } : null}
      onClick={closeByPoupBack}
      ref={popUpRef}
    >
      <div className={styles.UploadExtPopupWrapp}>
        <div className={styles.closePopup} onClick={closePoupHandler}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 12">
            <path fill="white" d="M.011 1.376L1.376.01 6 4.636 10.624.01l1.365 1.365L7.364 6l4.625 4.624-1.365 1.365L6 7.364 1.376 11.99.01 10.624 4.636 6z" />
          </svg>
        </div>
        <img src={popupHeart} alt="Every Search Matter" />
        <div className={styles.UploadExtPopup_content}>
          <h1>
            {t('earnHearts')}
            {' '}
            <br />
            {t('forYourSearches')}
          </h1>
          <h3>
            {t('donateRealMoney')}
          </h3>
          <div className={styles.UploadExtPopup_button}>
            <a href={`${EXT_PATH}${subId ? `?subid=${subId}` : '?subid=sitepopup'}`} rel="noopener noreferrer" target="_blank">
              <p>
                {t('installExt')}
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>

  );
};

export default UploadExtPopup;
