import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const Button = memo(({
  disabled, text, onClick, customButtonClass, data, stylesC
}) => (
  <div onClick={onClick} data-id={data && data.id} data-count={data && data.count} style={stylesC} className={`${disabled ? styles.buttonDisabled : styles.button} ${customButtonClass}`}>
    <p>{text}</p>
  </div>
));

Button.propTypes = {
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.string,
  onClick: PropTypes.func,
  customButtonClass: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
};

Button.defaultProps = {
  disabled: false,
  text: '',
  data: '',
  onClick: () => {},
  customButtonClass: '',
};

export default Button;
