import React, { useEffect, useState, Suspense } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import './scss/global.scss';
import Header from './components/Header';
import UploadExtPopup from './components/UploadExtPopup';
import canUseDOM from './utils/canUseDOM';
import ScrollToTopRoute from './components/ScrollToTopRoute';
import Popup from './components/Popup';
import Footer from './components/Footer';

const Terms = React.lazy(() => import('./pages/Terms'));
const Privacy = React.lazy(() => import('./pages/Privacy'));
const Landing = React.lazy(() => import('./pages/Landing'));
const Page404 = React.lazy(() => import('./pages/404'));
const Donate = React.lazy(() => import('./pages/Donate'));
const Contact = React.lazy(() => import('./pages/Contact'));
const About = React.lazy(() => import('./pages/About'));

function App() {
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 500);
  });
  const showPopup = location.pathname !== '/landing'
    && (/Mobile/i.test(navigator.userAgent)
    || (!/Mobile/i.test(navigator.userAgent) && canUseDOM && loaded));
  return (
    <div className="App">
      <Header />
      <div className="main">
        <Switch>
          <ScrollToTopRoute path="/search">
            <Landing />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact path="/">
            <Suspense fallback={<div />}>
              <Donate />
            </Suspense>
          </ScrollToTopRoute>
          <ScrollToTopRoute path="/privacy">
            <Suspense fallback={<div />}>
              <Privacy />
            </Suspense>
          </ScrollToTopRoute>
          <ScrollToTopRoute path="/terms">
            <Suspense fallback={<div />}>
              <Terms />
            </Suspense>
          </ScrollToTopRoute>
          <ScrollToTopRoute path="/contact">
            <Suspense fallback={<div />}>
              <Contact />
            </Suspense>
          </ScrollToTopRoute>
          <ScrollToTopRoute path="/about">
            <Suspense fallback={<div />}>
              <About />
            </Suspense>
          </ScrollToTopRoute>
          <ScrollToTopRoute path="/landing">
            <Suspense fallback={<div />}>
              <Landing />
            </Suspense>
          </ScrollToTopRoute>
          <ScrollToTopRoute>
            <Suspense fallback={<div />}>
              <Page404 />
            </Suspense>
          </ScrollToTopRoute>
        </Switch>
      </div>

      <Suspense fallback={<div />}>
        <Footer />
        <Popup />
        {showPopup && <UploadExtPopup />}
      </Suspense>

    </div>
  );
}

export default App;
