import { OPEN_POPUP } from '../types/popupTypes';

// eslint-disable-next-line import/prefer-default-export
export function openPopup(text, status, image) {
  return {
    type: OPEN_POPUP,
    text,
    status,
    image,
  };
}
