import React from 'react';
import {
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './style.module.scss';
import logo from '../../media/logo.svg';
import donatedHeartsIcon from '../../media/donated-hearts.svg';
import Button from '../Button';
import { InstallButton } from '../Landing';


const Header = () => {
  const donate = useSelector((state) => state.donate);
  const location = useLocation();
  return (
    <div className={styles.header}>
      <div className={styles.headerWrapp}>
        {location.pathname !== '/' ? (
          <a href="/">
            <img src={logo} alt="Every Search Matters" />
          </a>
        )
          : <img src={logo} alt="Every Search Matters" />}
        {
          location.pathname !== '/landing' && !donate.hearts
            ? <>
              <div className={` ${styles.headerAvailableHearts} headerAvailableHearts `}>
                <img src={donatedHeartsIcon} alt="Every Search Matters" />
                <p>{donate.hearts}</p>
              </div>
              <div className={` ${styles.headerAvailableHeartsPlaceholder} headerAvailableHeartsPlaceholder `} />
              </>
            : <InstallButton variant="outlined"/>
        }
      </div>
    </div>
  );
};

export default Header;
