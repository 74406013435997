import React from 'react';
import styles from './style.module.scss';

export default ({ item, index = 0 }) => (
  <div className={styles.card}>
    <svg className={styles[`img--${index}`]}>
      <use xlinkHref={item.img} />
    </svg>
    <p className={styles.card__title}>{item.title}</p>
    <p className={styles.card__description}>{item.description}</p>
  </div>
);
