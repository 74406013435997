import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { openPopup } from '../../redux/actions/popupActions';
import Button from '../Button';
import image1 from './images/1.jpeg';
import image10 from './images/10.jpeg';
import image11 from './images/11.jpg';

const Popup = () => {
  const popUp = useSelector((state) => state.popUp);
  const html = document.querySelector('html');
  const { status, text } = popUp;
  const dispatch = useDispatch();
  const popUpRef = useRef(null);
  const [srcImg, setImg] = useState(null);
  const images = [image1, image10, image11];
  const { t } = useTranslation();


  const closePoupHandler = () => {
    dispatch(openPopup('', false));
  };

  const onKeyDownHandler = (event) => {
    if (event.key === 'Escape') {
      closePoupHandler();
    }
  };

  if (status) {
    disableBodyScroll(html);
    document.addEventListener('keydown', onKeyDownHandler);
  } else {
    enableBodyScroll(html);
    document.removeEventListener('keydown', onKeyDownHandler);
  }
  const closeByPoupBack = (e) => {
    if (e.target === popUpRef.current) {
      dispatch(openPopup('', false));
    }
  };
  useEffect(() => {
    if (status) {
      setImg(images[Math.floor(Math.random() * 3)]);
    }
  }, [status]);
  return (
    <div
      className={`${status ? styles.popup : styles.popupClose} thankPopup`}
      onClick={closeByPoupBack}
      ref={popUpRef}
      onKeyDown={onKeyDownHandler}
    >

      <div className={`${styles.popupWrapp}`}>
        <div className={styles.closePopup} onClick={closePoupHandler}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 12">
            <path fill="#d3d3d3" d="M.011 1.376L1.376.01 6 4.636 10.624.01l1.365 1.365L7.364 6l4.625 4.624-1.365 1.365L6 7.364 1.376 11.99.01 10.624 4.636 6z" />
          </svg>
        </div>
        <h3 className={styles.popupTitle}>{text}</h3>
        {srcImg && <img src={srcImg} alt="Every Search Matters" />}
        <Button customButtonClass={styles.customButtonClass} text={t('done')} onClick={closePoupHandler} />
      </div>
    </div>
  );
};

export default Popup;
