import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const Footer = memo(({
  t,
}) => {
  const location = useLocation();
  return (
    <div className={styles.footer}>
      <div className={styles.footerWrapp}>
        <p>© 2020 everysearchmatters.com</p>
        <div className={styles.footerLinks}>
          {location.pathname === '/about'
            ? <a href="/about" style={{ pointerEvents: 'none' }}>{t('aboutTitle')}</a>
            : (

              <Link
                to="/about"
              >
                {t('aboutTitle')}
              </Link>
            )}
          {location.pathname === '/contact'
            ? <a href="/contact" style={{ pointerEvents: 'none' }}>{t('contactTitle')}</a>
            : (

              <Link
                to="/contact"
              >
                {t('contactTitle')}
              </Link>
            )}
          {location.pathname === '/privacy'
            ? <a href="/privacy" style={{ pointerEvents: 'none' }}>{t('privacyTitle')}</a>
            : <Link to="/privacy">{t('privacyTitle')}</Link>}
          {location.pathname === '/terms'
            ? <a href="/terms" style={{ pointerEvents: 'none' }}>{t('termsTitle')}</a>
            : (

              <Link
                to="/terms"
              >
                {t('termsTitle')}
              </Link>
            )}


        </div>
      </div>
    </div>
  );
});
Footer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  t: PropTypes.any,
};

Footer.defaultProps = {
  t: '',
};

export default withTranslation()(Footer);
